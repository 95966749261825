<template>
  <div class="sqs-error sqs-error--404">
    <div class="sqs-error-box">
      <img src="@/assets/wait.png" alt="" />
      <!-- <p>{{ messge }}</p> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { messge: "您访问的资源不存在，请检查请求地址是否正确！" };
  },
};
</script>
<style lang="scss" scoped>
.sqs-error.sqs-error--404 {
  text-align: center;
  font-size: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .sqs-error-box {
    width: 100%;
    text-align: center;
    p {
      font-size: 3rem;
      .sqs-error-back {
        cursor: pointer;
        color: #3366ff;
      }
    }
  }
}
</style>
